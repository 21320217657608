import React from 'react';

const PhilosophyMap: React.FunctionComponent = () => {
  return (
    <>
      <h2>A who's who of (early) modern philosophy</h2>
      <p>
        In an attempt to learn a bit of philosophy, I thought it would make
        sense to start with some early{' '}
        <a href="https://en.wikipedia.org/wiki/Modern_philosophy">
          modern philosophy
        </a>
        .
      </p>
      <p>
        The following diagram is an attempt at showing some major groupings,
        along with a rough sense of the timeline.
      </p>

      <img src="/modern-philosophy.png" alt="timeline of modern philosophy" />

      <p>
        Some familiarity with this landscape should provide a solid basis for
        investigating older as well as more contemporary philosophy.
      </p>
    </>
  );
};

export default PhilosophyMap;
