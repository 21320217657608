import React from 'react';
import useMathJax from '../../useMathJax';

const RuleOf4And2: React.FunctionComponent = () => {
  useMathJax();

  return (
    <>
      <h2>The Rule of 4 and 2</h2>
      <p>
        In Texas Holdem poker, the "rule of 4 and 2" is a basic guideline to
        calculating the odds of hitting your hand for a given number of{' '}
        <a href="http://en.wikipedia.org/wiki/Out_%28poker%29">outs</a>. It says
        that if you have one street left, then you need to double the number of
        outs, to get the percentage chance of hitting one of them. If you have
        two streets, multiply the number of outs by 4.
      </p>
      <p>
        For example, if you have Q♠ 5♠, and the flop comes 7♠ 2♥ T♠, then you
        have 9 outs (any spade) to hit a flush. The rule tells you that you will
        make a flush by the river 36% of the time. If you miss on the turn, you
        will make the flush on the river 18% of the time.
      </p>

      <p>
        You can then compare these odds to the pot odds, in order to determine
        whether you are getting the right odds to call a given bet.
      </p>
      <p>
        But where does the rule come from? Well, after the flop, you have seen 5
        out of the 52 cards in the deck, leaving 47 cards unseen. If you have 9
        outs, then the probability that the next card will be one of those outs
        is 9/47, or about 19%.
      </p>

      <p>
        Ok, so let's calculate the odds for hitting our outs in various
        situations. In the following calculations, \(p(x)\) represents the
        probability that \(x\) occurs.
      </p>
      <p>
        If we need to hit any one of our outs to make our hand, then the
        probability of making our hand on the turn is
        {String.raw`$$p(\text{on the turn}) = \frac{\text{outs}}{47} \approx (\mathbf{2.1}\times\text{outs})\%$$`}
      </p>
      <p>
        If we miss on the turn, then the probability of making our hand on the
        river is
        {String.raw`$$p(\text{on the river}) = \frac{\text{outs}}{46} \approx (\mathbf{2.2}\times\text{outs})\%$$`}
      </p>
      <p>
        The probability of hitting one of our outs on either the turn{' '}
        <em>or</em> the river is
        {String.raw`
$$ \begin{align*}
p(\text{on the turn or river}) &= 1 - (p(\text{not on the turn}) \times p(\text{not on the river}))
\\ &= 1 - (\frac{47 - \text{outs}}{47} \times \frac{46 - \text{outs}}{46})
= \frac{(93-\text{outs})}{2162}\text{outs}
\\ &\approx (\mathbf{4.3}\times\text{outs})\% \hspace{2pc}\text{where outs = 1}
\\ &\approx (\mathbf{4.0}\times\text{outs})\% \hspace{2pc}\text{where outs = 7}
\\ &\approx (\mathbf{3.7}\times\text{outs})\% \hspace{2pc}\text{where outs = 13}
\end{align*} $$
`}
      </p>
      <p>
        So, you see, the "rule of 4 and 2" gives a fairly good estimate of the
        correct answer, and is much easier than trying to calculate the exact
        odds in your head in the middle of a hand.
      </p>
    </>
  );
};

export default RuleOf4And2;
