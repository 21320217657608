import React from 'react';
import { Link } from 'react-router-dom';

const Pages: React.FunctionComponent = () => {
  return (
    <>
      <h2>Misc Pages</h2>
      <ul>
        <li>
          <Link to="/guitar-scales">Guitar scales made simple</Link>
        </li>
        <li>
          <Link to="/modern-philosophy">A who's who of (early) modern philosophy</Link>
        </li>
        <li>
          <Link to="/git-cheatsheet">Git cheatsheet</Link>
        </li>
        <li>
          <a href="/dev/poker-tda-rules">Poker TDA Rules</a>
        </li>
        <li>
          <Link to="/rule-of-4-and-2">Rule of 4 and 2 (in poker)</Link>
        </li>
      </ul>
    </>
  );
};

export default Pages;
