import React from 'react';
import './Software.css';

const Software: React.FunctionComponent = () => {
  return (
    <>
      <h2>Portfolio</h2>

      <dl className="project-list">
        <dt>
          <a href="http://www.xero.com/">Xero</a> (2014 - present)
        </dt>
        <dd>
          Online accounting software for small businesses. Development in
          C#/.NET, JavaScript/TypeScript/React, and SQL.
        </dd>
        <dt>
          <a href="http://www.oceanbrowser.com/">Ocean Browser</a> (2011)
        </dt>
        <dd>
          Collaborative document authoring and learning environment. Research,
          and JavaScript development.
        </dd>
      </dl>

      <h2>Personal Projects</h2>

      <p>
        Here are some apps I have made in my spare time. Most are just
        prototypes, so may be lacking in aesthetics.
      </p>

      <dl className="project-list">
      <dt>
          <a href="http://metro-x.s3-website-us-west-2.amazonaws.com/">
            Metronome
          </a>
        </dt>
        <dd>
          A metronome for practicing guitar etc
        </dd>
        <dt>
          <a href="http://chordnames.s3-website-us-east-1.amazonaws.com/">
            ChordNames
          </a>
        </dt>
        <dd>
          A tool for generating possible chord names for a collection of notes.
        </dd>
        <dt>
          <a href="dev/fretboard/">Fretboard Tutor</a>
        </dt>
        <dd>
          Visualisation of the guitar fretboard for various scale and chord
          shapes
        </dd>
        <dt>
          <a href="dev/EventReactor">EventReactor</a>
          <a
            href="dev/EventReactor/EventReactor.js"
            className="source"
          >
            [Source]
          </a>
        </dt>
        <dd>
          A re-imagining of the Eventfinda search interface (built in react)
        </dd>
        <dt>
          <a href="http://www.miksd.com">Miksd.com</a>
          <a href="https://github.com/akg1852/Miksd" className="source">
            [GitHub]
          </a>
        </dt>
        <dd>Cocktail app</dd>
        <dt>
          CheezeBot
          <a href="https://github.com/akg1852/CheezeBot" className="source">
            [GitHub]
          </a>
        </dt>
        <dd>A slack bot (built in nodejs)</dd>
        <dt>
          <a href="dev/hak-pak">Hak Pak</a>
          <a href="https://github.com/akg1852/Hak-Pak" className="source">
            [GitHub]
          </a>
        </dt>
        <dd>A Pac-Man game implementation</dd>
        <dt><a href="dev/poker-tda-rules">Poker TDA Rules</a></dt>
        <dd>Interactive version of the official Poker TDA rules</dd>
        <dt>
          <a href="dev/webnotebook">WebNotebook</a>
          <a href="https://github.com/akg1852/WebNotebook" className="source">
            [GitHub]
          </a>
        </dt>
        <dd>Digital notebook in the style of a paper notebook.</dd>
        <dt>adamkgray.com</dt>
        <dd>My personal website (this site).</dd>
      </dl>
    </>
  );
};

export default Software;
