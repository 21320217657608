import { useEffect } from 'react';

declare const MathJax: any;

const useMathJax = () => {
  useEffect(() => {
    MathJax.typeset();
  });
}

export default useMathJax;