import React from 'react';

const NotFound: React.FunctionComponent = () => {
  return (
    <>
      <h2>Page Not Found</h2>
      <p>Sorry, the page you are looking for couldn't be found.</p>
    </>
  );
};

export default NotFound;
