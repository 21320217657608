import React from 'react';
import { Link } from 'react-router-dom';

const Home: React.FunctionComponent = () => {
  const age = getAge(new Date(1984, 4, 20));
  return (
    <>
      <p>
        Hi, my name is Adam Gray, I'm {age} years old. I grew up in Redditch,
        England. In 2003, I moved to New Zealand, and currently live in
        Wellington.
      </p>
      <p>
        In 2010, I completed a BSc in Mathematics and Computer Science at the
        University of Canterbury, NZ. Since 2011, after many years of messing
        around with coding, I finally started taking{' '}
        <Link to="/software">software development</Link> more seriously.
      </p>
      <p>My main interests are music/guitar, coding, reading, and cocktails.</p>
    </>
  );
};

function getAge(dateOfBirth: Date) {
  const now = new Date();
  const currentYear = now.getFullYear();

  const birthday = new Date(
    currentYear,
    dateOfBirth.getMonth(),
    dateOfBirth.getDate()
  );
  const birthdayHappened = birthday <= now;

  return currentYear - dateOfBirth.getFullYear() - (birthdayHappened ? 0 : 1);
}

export default Home;
