import React from 'react';

const GuitarScales: React.FunctionComponent = () => {
  return (
    <>
      <h2>Guitar scales made simple</h2>
      <p>
        Major and Minor scales in all 5 positions, explained using two simple
        one-octave shapes, and two simple facts.
      </p>

      <h3>The shapes</h3>
      <p>
        There are two shapes for a <strong>one-octave minor scale</strong>.
      </p>
      <img src="/scale-shapes.png" alt="minor scale shapes" />
      <p>
        Before continuing, take some time to learn to play the minor scale in
        these two shapes, using one finger per fret, on the EADG strings of the
        guitar.
      </p>
      <p>
        Note that the left shape uses fingers 1-2-4 and the right shape uses
        fingers 1-3-4.
      </p>

      <h3>The facts</h3>
      <p>There are also two facts you need to know:</p>
      <ul>
        <li>
          <strong>The B string is weird</strong> - Due to an inconsistency in
          the way a guitar is tuned, the shapes need to be shifted to the right
          by one fret when crossing from the G to B strings.
        </li>
        <li>
          <strong>Relative Major/Minor</strong> - The major scale is found by
          starting the minor scale from the 3rd degree (or, equivalently, the
          minor scale is found by starting the major scale from the 6th degree).
        </li>
      </ul>
      <p>
        The two shapes and two facts will be used to build full scales across
        the fretboard.
      </p>

      <h3>Minor scale in 5 positions</h3>
      <p>
        By stacking the two shapes on top of each other, starting on different
        strings, we get 5 different “positions” for the minor scale:
      </p>
      <img
        src="/scale-positions.png"
        alt="minor scale positions"
        width="100%"
      />
      <p>
        Note that in each diagram, the top two rows of the pattern are always
        shifted one place to the right.
      </p>

      <h3>Major scale</h3>
      <p>There are also two one-octave major scale shapes.</p>
      <p>
        These are found by starting the minor-scale shapes on the third degree.
        Thus, these shapes are found in the overlap between the minor scale
        shapes.
      </p>
      <img src="/scale-shapes-major.png" alt="major scale shapes" />
      <p>
        The 5 minor scale positions can be re-labeled with the major scale
        degrees, to generate the major scale in all 5 positions. This is left as
        an exercise to the reader.
      </p>
    </>
  );
};

export default GuitarScales;
