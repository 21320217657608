import React from 'react';

const GitCheatsheet: React.FunctionComponent = () => {
  return (
    <>
      <h2>Git Cheatsheet</h2>
      <p>
        There are plenty of decent{' '}
        <a href="http://pcottle.github.io/learnGitBranching/">git tutorials</a>{' '}
        out there, and the{' '}
        <a href="http://git-scm.com/docs/">official documentation</a> is
        thorough (if not a little intimidating), but a nice simple list of
        common commands could come in handy. So here we go.
      </p>

      <p>Commands:</p>
      <pre>
        {`  git config --global user.name|user.email|core.editor <value>
  git init
  git clone <repository url> [<directory>]
  git remote add <repository> <repository url>

  git fetch [<repository> [<refspec>]]
  git pull [--rebase] [<repository> [<refspec>]]
  git push [--tags] [<repository> [<refspec>]]

  git add [-u] [<pathspec>]
  git commit [-m <message>]
  git branch [-f] [-u] [-d] [-r] [<branch>] [<reference>]
  git checkout [-b <branch>] [<reference>]
  git merge <reference>
  git rebase [-i] <base reference> [<head reference>]
  git cherry-pick <reference> [<reference> [<...>]]
  git stash [<message> | pop | list]

  git status
  git log [--patch] [--all] [--graph] [--decorate] [--oneline] [<pathspec>]
  gitk
  git show <reference>
  git diff [--cached] [<reference> [<reference>]]
  git difftool
  git mergetool
  git reset [--hard] <reference>
  git revert <reference>
  git tag <tag> [<reference>]
  git describe [<reference>]
  git clean -xdf
  git grep [-i] <pattern> [<pathspec>]
  git bisect [start | bad [<reference>] | good [<reference>] | reset [<reference>]]
  git reflog`}
      </pre>

      <p>References:</p>
      <pre>
        {`  <reference> = <branch> | <commit> | <tag> | HEAD | <relative reference>
  <relative reference> = <reference>^[<parent num>] | <reference>~[<ancestor num>]
  <refspec> = [+][<source>][:<destination>]`}
      </pre>
    </>
  );
};

export default GitCheatsheet;
