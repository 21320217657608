import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from 'react-router-dom';

import Home from './Home';
import NotFound from './NotFound';
import Software from './Software';
import Pages from './Pages';
import GuitarScales from './pages/GuitarScales';
import PhilosophyMap from './pages/PhilosophyMap';
import GitCheatsheet from './pages/GitCheatsheet';
import RuleOf4And2 from './pages/RuleOf4And2';

const pages = [
  { url: '/software', Page: Software },
  { url: '/pages', Page: Pages },
  { url: '/guitar-scales', Page: GuitarScales },
  { url: '/modern-philosophy', Page: PhilosophyMap },
  { url: '/git-cheatsheet', Page: GitCheatsheet },
  { url: '/rule-of-4-and-2', Page: RuleOf4And2 },
];

const App: React.FunctionComponent = () => {
  return (
    <Router>
      <header>
        <h1>adamkgray.com</h1>
        <nav>
          <ul>
            <li>
              <NavLink exact to="/">
                About
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/software">
                Software Development
              </NavLink>
            </li>
            <li>
              <NavLink exact to="/pages">
                Miscellaneous
              </NavLink>
            </li>
          </ul>
        </nav>
      </header>

      <main>
        <Switch>
          {pages.map(({ url, Page }) => (
            <Route key={url} exact path={url}>
              <Page />
            </Route>
          ))}
          <Route exact path="/">
            <Home />
          </Route>
          <Route exact path="*">
            <NotFound />
          </Route>
        </Switch>
      </main>
      <footer>
        © Adam Gray 1998 - {new Date().getFullYear()}.{' '}
        <a href="https://creativecommons.org/licenses/by-nc/4.0/">
          Some rights reserved
        </a>
        .
      </footer>
    </Router>
  );
};

export default App;
